import React from 'react';

const NotFound = () => {
    return (
    <>
    <h1>
        404 Not found
    </h1>
    <h2>
        this page does not exist.
    </h2>
    </>
    )
}

export default NotFound;